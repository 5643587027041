<template>
  <div>Starting user session...</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SessionSection',
  methods: {
    ...mapActions(['tokenAc', 'sessionTokenAc', 'sessionAc', 'errorAc', 'userDetailsAc']),

    async startSession() {
      try {
        const token = this.getToken || '';
        const sessionData = await this.sessionAc(token);

        const isKycNeed = sessionData.data.isKycNeed;
        const sToken = sessionData.data.sessionToken;

        await this.sessionTokenAc(sToken);
        await this.tokenAc('');

        if (isKycNeed) {
          this.$router.push({ path: '/kyc', query: { session_token: sToken } });
        } else {
          await this.userDetailsAc();

          this.$router.push({
            path: '/start',
            query: { session_token: sToken }
          });
        }
      } catch (err) {
        console.error(err);

        const statusCode = (err.response && err.response.status) || 500;
        const message = (err.response && err.response.data && err.response.data.message) || err.message;
        await this.errorAc({ statusCode, message });
        this.$router.push({ path: '/error', query: { statusCode } });
      }
    }
  },
  computed: {
    ...mapGetters(['getToken'])
  },
  async mounted() {
    try {
      await this.startSession();
    } catch (err) {
      console.error(err);

      const statusCode = (err.response && err.response.status) || 500;
      const message = (err.response && err.response.data && err.response.data.message) || err.message;
      await this.errorAc({ statusCode, message });
      this.$router.push({ path: '/error', query: { statusCode } });
    }
  }
};
</script>

<style scoped></style>
