import axios from 'axios';

const ENV = process.env.VUE_APP_STAGING_ENV;
const BASE_URL = ENV ? process.env.VUE_APP_API_URL_DEV : process.env.VUE_APP_API_URL_PROD;

export default {
  tokenAc({ commit }, token) {
    commit('SET_TOKEN', token);
  },

  sessionTokenAc({ commit }, token) {
    commit('SET_SESSION_TOKEN', token);
  },

  errorAc({ commit }, payload) {
    commit('SET_ERROR', payload);
  },

  async fetchAssetsAc({ state, commit }) {
    const url = BASE_URL + '/assets';
    if (!state.sessionToken) {
      throw new Error('Missing token');
    }

    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.get(url, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data.data;
      commit('SET_ASSETS', data);
      return data;
    } catch (error) {
      console.error(`Error fetching assets: ${error.message}`);
      throw new Error(error.message);
    }
  },
  async fetchCurrencyAc({ state, commit }) {
    const url = BASE_URL + '/currency';
    if (!state.sessionToken) {
      throw new Error('Missing token');
    }

    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.get(url, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data.data;
      commit('SET_CURRENCIES', data);
      return data;
    } catch (error) {
      console.error(`Error fetching assets: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async sessionAc({ commit }, token) {
    if (!token) {
      throw new Error('Missing token');
    }

    const url = BASE_URL + '/session';
    const payload = {};
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    try {
      const response = await axios.post(url, payload, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      commit('SET_SESSION_DATA', response.data.data);
    } catch (error) {
      console.error(`Error create user session: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async kycAc({ state }, payload) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + `/users/${state.userDetails.user_key}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.patch(url, payload, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Error update user details: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async userCountries({ state }) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + '/users/countries';
    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.get(url, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Error update user details: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async userDetailsAc({ state, commit }) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + '/users/details';
    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.get(url, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data;
      commit('SET_USER_DETAILS', data.data);
      return data;
    } catch (error) {
      console.error(`Error fetching user details: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async recipientAc({ state }, payload) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + '/recipients';
    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.post(url, payload, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Error creating recipient: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async recipientCtryListAc({ state }, payload) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + `/recipients/country/?currency=${payload.currency}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.get(url, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Error creating recipient: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async recipientUpdateAc({ state }, payload) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + '/recipients/update/' + payload.rkey;
    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.patch(
        url,
        {
          dob: payload.dob,
          mobile_number: payload.mobile_number
        },
        headers
      );

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Error creating recipient: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async calculateAc({ state, commit }, payload) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + '/quote';
    // const url = BASE_URL + '/calculate';
    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.post(url, payload, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data;
      commit('SET_EXCHANGE_RATE', data.data);
      commit('RESET_ERROR');
      return data;
    } catch (error) {
      console.error(error);

      const statusCode = error.response?.status || 500;
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      commit('SET_ERROR', {
        statusCode,
        message
      });
      throw error;
    }
  },

  async calculateUpdateAc({ state, commit }, payload) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + '/quote';
    // const url = BASE_URL + '/calculate/update';
    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.post(url, payload, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data;
      commit('SET_EXCHANGE_RATE', data.data);
      commit('RESET_ERROR');
      return data;
    } catch (error) {
      console.error(error);

      const statusCode = error.response?.status || 500;
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      commit('SET_ERROR', {
        statusCode,
        message
      });
      throw error;
    }
  },

  async txStartAc({ state }, payload) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + '/transaction/start';
    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.post(url, payload, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Error start transaction: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async txConfirmAc({ state }, payload) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + '/transaction/confirm';
    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.post(url, payload, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data;
      return data;
    } catch (error) {
      console.error(`Error confirm transaction: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async fetchKycStatus({ state, commit }) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + '/kyc/verify';

    const payload = {
      transaction_id: state.transaction_id,
      reference_key: state.referenceKey
    };

    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.post(url, payload, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data.data;
      commit('SET_KYC_DATA', data);
      return response.data;
    } catch (error) {
      console.error(`Error fetching kyc details: ${error.message}`);
      const err = new Error('KYC verification failed');
      err.statusCode = 400;
      throw err;
    }
  },

  async fetchTxDetails({ state, commit }) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }
    const callback = state.callback;
    const onChangeCallback = state.onChangeCallback;

    const url = `${BASE_URL}/transaction/details?callback=${callback}&onChangeCallback=${onChangeCallback}`;

    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.get(url, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data.data;
      commit('SET_KYC_URL', data);
      commit('SET_RECIPIENT_INFO', data);
      commit('SET_TRANSACTION_INFO', data);
      return data;
    } catch (error) {
      console.error(`Error fetching transaction details: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async fetchTxAc({ state }) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + '/transaction';
    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.get(url, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      return response.data;
    } catch (error) {
      console.error(`Error fetching transaction: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async fetchTxByIDAc({ state, commit }) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + '/transaction/key';
    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.get(url, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const tx = response.data.data.transaction;

      const txAmountInfo = {
        amount_in: tx.amount_in,
        amount_in_currency: tx.amount_in_asset,
        amount_out: tx.amount_out,
        amount_out_currency: tx.amount_out_asset,
        amount_fee: tx.amount_fee,
        indicative_crypto_amt: tx.indicative_crypto_amt
      };

      commit('SET_AMOUNT_INFO', txAmountInfo);

      return response.data;
    } catch (error) {
      console.error(`Error fetching transaction: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async createTxAc({ state, commit }) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + '/transaction';
    const payload = {
      exchange_rate: state.exchangeRate.exchange_rate,
      exchange_rate_USD: state.exchangeRate.exchange_rate_USD,
      amount_in: +state.txDetails.amount.amount_in,
      amount_in_asset: state.txDetails.amount.amount_in_currency,
      amount_out: +state.txDetails.amount.amount_out,
      amount_out_asset: state.txDetails.amount.amount_out_currency,
      amount_fee: +state.txDetails.amount.amount_fee,
      amount_fee_asset: state.txDetails.amount.amount_out_currency,
      indicative_crypto_amt: state.txDetails.amount.indicative_crypto_amt,
      currency: state.selectedCurrency,
      quote_id: state.exchangeRate.quote_id
    };

    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.post(url, payload, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data;
      commit('SET_TRANSACTION', data.data);
      return response.data;
    } catch (error) {
      console.error(`Error creating transaction: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async recpientLinkTxAc({ state, commit }, { recipientKey }) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + `/recipients/${recipientKey}/link/${state.transaction.tx_key}`;

    const payload = {};
    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.patch(url, payload, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data;
      commit('SET_TRANSACTION', data.data);
      return response.data;
    } catch (error) {
      console.error(`Error recipient link to transaction: ${error.message}`);
      throw new Error(error.message);
    }
  },

  async getBaseExchangeRate({ commit }, payload) {
    const url = BASE_URL + '/exchange-rate';

    try {
      const response = await axios.post(url, payload);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const data = response.data;
      commit('SET_BASE_EXCHANGE_RATE', data.data);
      commit('RESET_ERROR');
    } catch (error) {
      console.error(error);

      const statusCode = error.response?.status || 500;
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      commit('SET_ERROR', {
        statusCode,
        message
      });
      throw error;
    }
  },

  // bank list
  async fetchBankList({ state, commit }, payload) {
    if (!state.sessionToken) {
      throw new Error('Missing session token');
    }

    const url = BASE_URL + `/recipients/bank/list/?countryCode=${payload?.countryCode || ''}`;
    const headers = {
      headers: {
        Authorization: `Bearer ${state.sessionToken}`
      }
    };

    try {
      const response = await axios.get(url, headers);

      if (!response.data) {
        throw new Error('Unexpected response from server');
      }

      const banks = response.data.data;

      commit('SET_BANK_LIST', banks);

      return response.data;
    } catch (error) {
      console.error(`Error fetching transaction: ${error.message}`);
      throw new Error(error.message);
    }
  },

  resetErrorsAction({ commit }, payload = {}) {
    commit('RESET_ERROR', payload);
  }
};
